<template>
  <div class="orderList">
     <div class="title">
        <img src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>{{name}}</span>
     </div>
     <div class="ruletext" v-html="ruleText"></div>
     <bottomList></bottomList>
  </div>
</template>

<script>
import { getRule } from '@/api/user'
export default {
  name: "openDeal",

  data() {
    return {
        ruleId: '',
        ruleText: '',
        name: '',
    };
  },

  mounted() {
    if(this.$route.params.id) {
        localStorage.setItem('ruleId', this.$route.params.id)
    } else {
        this.$route.params.id = localStorage.getItem('ruleId')
    }
    this.getRule(this.$route.params.id)
    if (this.$route.params.id == 3) {
        this.name = '售后退款物流政策'
    } else if (this.$route.params.id == 4) {
        this.name = '用户协议'
    } else if (this.$route.params.id == 5) {
        this.name = '隐私保护指引'
    }
  },

  methods: {
    getRule(id) {
        getRule(id).then(res => {
            this.ruleText = res.data
        })
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.orderList{
    width: 100vw;
    height: 100vh;
   background: #fff;
}
.title{
    width: 100%;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 11;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
.ruletext{
    padding: 0rem 15rem;
    padding-top: 60rem;
    line-height: 1.7;
    font-size: 13rem;
}
</style>
